<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/task`" title="Task" :columns="columns"
                routerpath="/crm/task/addtask" :formOptions="formOptions" :newPage="true" :edit="1" :add="1"
                :canDelete="1" :viewFields="viewFields"
                 />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      edit: "",
      add: "",
      canDelete: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      title: "Widget",
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Task" : "Add Task"}`,
        submitRouterPath: "/crm/task",
        gridForm: true,
        method: "post",
        action: "add",
        getEditValue: `${baseApi}/gettaskById`,
        url: `${baseApi}/task`,
        inputFields: [
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Assign To",
            name: "assignto",
            value: {},
            type: "dropdown",
            placeholder: "Enter Name",
            class: "col-md-6",
            dropdownname: "parent",
            url: "getUsers",
            required: true,
            options: [],
          },
          {
            label: "Task Type",
            name: "tasktype",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "tasktype",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: ['Office Work'],
          },
          {
            label: "Task",
            name: "task",
            value: "",
            type: "text",
            placeholder: "Enter task",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Priority",
            name: "Priority",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            required: true,
            options: ['High', 'Medium', 'Low'],
          },
          {
            label: "Description",
            name: "description",
            value: "",
            type: "text",
            placeholder: "Enter description",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Status",
            name: "status",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            required: true,
            options: ['Open', 'Medium', 'Low'],
          },
          {
            label: "Dead Line",
            name: "deadline",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select date",
            class: "col-md-6",
          },

        ],
      },
      viewFields:[
        {
          label:'Assign To',
          field:'assignto.assignto',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Task Type',
          field:'tasktype',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Task',
          field:'task',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Priority',
          field:'priority',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Description',
          field:'description',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Status',
          field:'status',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Dead Line',
          field:'deadline',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Attachment 1',
          field:'attachment1',
          type:'attachment',
          class:'col-md-4'
        },
        {
          label:'Attachment 2',
          field:'attachment2',
          type:'attachment',
          class:'col-md-4'
        },
        {
          label:'Remarks',
          field:'remarks  ',
          type:'text',
          class:'col-md-4'
        },
      ],
      columns: [
        {
          label: "Assign By",
          field: "enterby",
          responseValue: "fullname",
          url: "getUsers",
          type: "staticdropdown",
          filterOptions: {
            enabled: true,
            placeholder: "Search Assign By",
          },
        },
        {
          label: "Assign To",
          responseValue: "fullname",
          url: "getUsers",
          type: "staticdropdown",
          field: "assignto",
          filterOptions: {
            enabled: true,
            placeholder: "Search Assign To",
          },
        },
        {
          label: "Task Type",
          field: "tasktype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Task Type",
          },
        },
        {
          label: "Task",
          field: "task",
          filterOptions: {
            enabled: true,
            placeholder: "Search Task",
          },
        },
        {
          label: "Priority",
          field: "priority",
          filterOptions: {
            enabled: true,
            placeholder: "Search Priority",
          },
        },
        {
          label: "Description",
          field: "description",
          filterOptions: {
            enabled: true,
            placeholder: "Search Description",
          },
        },
        {
          label: "Status",
          field: "status",
          filterOptions: {
            enabled: true,
            placeholder: "Search Status",
          },
        },
        {
          label: "Dead Line",
          field: "deadline",
          type:'datetime',
          filterOptions: {
            enabled: true,
            placeholder: "Search Dead Line",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          responseValue: "fullname",
          url: "getUsers",
          type: "staticdropdown",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
         {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
            responseValue: "fullname",
          url: "getUsers",
          type: "staticdropdown",

          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    this.role = JSON.parse(localStorage.getItem("userData")).role;
    // if (this.role !== "admin") {
    //   userData.map((item,index) => {
    //     if (item.modulename == "Task") {
    //       this.edit = item.edit;
    //       this.add = item.add;
    //       this.canDelete = item.delete;
    //       console.log(item,index)
    //       if (
    //         item.edit !== 1 &&
    //         item.add !== 1 &&
    //         item.delete !== 1 &&
    //         item.view !== 1
    //       ) {
    //         this.$router.push("/");
    //       }
    //     }
    //   });
    // }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
